"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScapiBrazeApi = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _utils = require("../utils");
class ScapiBrazeApi {
  constructor(config) {
    this.fetch = (0, _utils.createScapiFetch)(config, 'braze');
  }
  subscriptionState(...args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      const url = `subscription/state`;
      return _this.fetch(url, 'POST', args, args === null || args === void 0 ? void 0 : args[1]);
    })();
  }
}
exports.ScapiBrazeApi = ScapiBrazeApi;